<template>
  <div :class="classes">
    <!-- Login -->
    <form
      :class="classes"
      @submit="onSubmit"
      v-if="!loading">

      <!-- Error banner -->
      <notice-banner
        :description="errorsLogin"
        :intent="$pepper.Intent.DANGER"
        v-if="hasErrors"
      />

      <!-- Email -->
      <forms-input
        autofocus
        :errors="getErrors('email')"
        name="email"
        :placeholder="$t('resto.user_email_placeholder')"
        ref="email"
        required
        type="email"
        @input="onRemoveError('email')"
        v-model="user.email"
      >{{ $t('resto.user_email') }}</forms-input>

      <!-- Password -->
      <forms-input
        :errors="getErrors('password')"
        name="password"
        :placeholder="$t('resto.user_password_placeholder')"
        ref="password"
        required
        type="password"
        @input="onRemoveError('password')"
        v-model="user.password"
      >{{ $t('resto.user_password') }}</forms-input>
    </form>

    <!-- Forgot password -->
    <actions-button
      appearance="subtle"
      class="user-forms-login__link"
      type="submit"
      @click.stop.prevent="$emit('lost-password')"
    >{{ $t('resto.user_lost_password_action')}}</actions-button>
  </div>
</template>

<script>
import MixinError from '@/helpers/errors'

export default {
  name: 'FormsLogin',

  mixins: [MixinError],

  model: {
    prop: 'user',
    event: 'change',
  },

  props: {
    errors: {
      type: Object | Array,
    },

    user: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      passwordFieldType: 'password',
    }
  },

  computed: {
    classes() {
      return {
        'user-forms-login': true,
        'flow': true,
      }
    },

    errorsLogin() {
      return this.$t('resto.user_login_error')
    },

    hasErrors() {
      return !this.$basil.isNil(this.errors) && !this.$basil.isEmpty(this.errors)
    },

    loading() {
      return this.user == null
    },
  },

  methods: {
    onSubmit(e) {
      e.preventDefault();
      return
    }
  },

  mounted() {
    this.user.password = null;
  },
}
</script>
