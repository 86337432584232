<template>
  <main
    @keyup="onKeyUp"
    v-if="!isModeTt">
    <div class="container -block">
      <!-- Header -->
      <header class="user__header">
        <h1 class="user__title">{{ $t('resto.user_login_title')}}</h1>
      </header>

      <div class="user__body">
        <!-- Login -->
        <section
          class="user__login flow"
          v-if="!lostPassword">
          <forms-login
            :errors="errors"
            :key="key"
            @lost-password="onLostPasswordShow"
            v-model="user"
          />

          <actions-button
            appearance="primary"
            class="fill"
            :disabled="isLogging"
            :loading="isLogging"
            @click="onLogin"
          >{{ $t('resto.action_confirm') }}</actions-button>
        </section>

        <!-- Lost Password -->
        <section
          class="user__login flow"
          v-if="lostPassword">
          <forms-lost-password
            :errors="errors"
            v-model="userPassword"
          />

          <actions-button
            appearance="primary"
            class="fill"
            :disabled="isLogging"
            :loading="isLogging"
            @click="onLostPasswordClick"
          >{{ $t('resto.action_confirm') }}</actions-button>
        </section>

        <!-- Subscribe -->
        <section class="user-auth__alt user__subscribe">
          <div class="user-auth__label">{{ $t('resto.user_user_login_subscribe') }}</div>

          <actions-button
            :href="signupRoute"
            :size="$pepper.Size.S"
          >{{ $t('resto.action_signup') }}</actions-button>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import FormsLogin from '../../components/forms/login'
import FormsLostPassword from '../../components/forms/lost-password'
import MixinError from '@/helpers/errors'

export default {
  name: 'UserLoginView',

  inject: [
    '$user',
    '$localStorage'
  ],

  components: {
    FormsLogin,
    FormsLostPassword
  },

  mixins: [
    MixinError,
  ],

  data() {
    return {
      errors: {},
      key: 1,
      lostPassword: false,
      userPassword: {},
      isLogging: false
    }
  },

  computed: {
    isModeTt() {
      return this.$localStorage.mode === 'tt' && this.$localStorage.service === 'eatin';
    },

    signupRoute() {
      let name = this.$route.name.replace('login', 'signup')
      return { name }
    },

    confirmRoute() {
      return { name: 'sayl-front-user.home' }
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    },
  },

  methods: {
    onKeyUp(ev) {
      if(ev.code === 'Enter') {
        this.onLogin()
      }
    },

    onLostPasswordShow(){
      this.userPassword = {
        email: this.user.email || null,
        token: null,
        isTokenShown: false,
        password: null,
        passwordConfirm: null
      };
      this.errors = {};
      this.lostPassword = true;
    },

    onLostPasswordClick(){
      this.errors = {}
      this.isLogging = true

      if(!this.userPassword.isTokenShown) {
        this.$user.lostPassword({ email: this.userPassword.email })
          .then(() => this.userPassword.isTokenShown = true)
          .catch((e) => this.handleErrors(e, 'lost-password'))
          .finally(() => {
            this.key++
            this.isLogging = false
          })
      } else {
        this.$user.changePassword({ email: this.userPassword.email, token: this.userPassword.token, password: this.userPassword.password, passwordConfirm: this.userPassword.passwordConfirm })
          .then(() => this.$router.replace(this.confirmRoute))
          .catch((e) => this.handleErrors(e, 'lost-password'))
          .finally(() => {
            this.key++
            this.isLogging = false
          })
      }
    },

    onLogin() {
      this.isLogging = true
      this.errors = {}

      this.$user.login({ email: this.user.email, password: this.user.password })
        .then((user) => {
          let token = this.$localStorage['firebase-token']
          let context = this.$localStorage.context

          if(context === 'mobile' && token) {
            this.$user.setFirebaseToken({ token })
          }

          //sayl.injekt.sp.userId = user.id
          this.$router.push(this.confirmRoute).catch(() => {})
        })
        .catch((e) => this.handleErrors(e))
        .finally(() => {
          this.isLogging = false;
          this.key++
        })
    },
  },

  mounted() {
    this.errors = {};
    this.lostPassword = false;

    if(this.user && this.user.isLogged) {
      this.$router.push({ name: 'sayl-front-user.home'}).catch(() => {})
    }
  }
}
</script>
