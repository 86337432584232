<template>
  <form 
    :class="classes"
    :key="key"
    @submit="onSubmit">
    <notice-banner 
      :description="$t('resto.user_lost_password_error')"
      intent="danger"
      v-if="errors['lost-password']"
    /> 

    <!-- Email -->
    <forms-input 
      autofocus
      autocomplete
      :errors="getErrors('email')"
      name="email"
      :placeholder="$t('resto.user_email_placeholder')"
      ref="email"
      required
      v-if="!value.isTokenShown"
      v-model="value.email"
    >{{ $t('resto.user_email') }}</forms-input>

    <!-- Token -->
    <forms-input 
      :errors="errors['token']"
      :hint="$t('resto.user_lost_password_token_hint')"
      name="token"
      :placeholder="$t('resto.user_lost_password_token_placeholder')"
      ref="token"
      required
      v-if="value.isTokenShown"
      v-model="value.token"
    >{{ $t('resto.user_lost_password_token') }}</forms-input>

    <!-- Password -->
    <forms-input 
      :errors="errors['password']"
      @iconClick="onChangePasswordVisible"
      :icon-post="passwordFieldIcon"
      :icon-post-interactive="true"
      name="password"
      :placeholder="$t('resto.user_password_placeholder')"
      ref="password"
      required
      :type="passwordFieldType"
      v-if="value.isTokenShown"
      v-model="value.password"
    >{{ $t('resto.user_password') }}</forms-input>

    <!-- Password confirmation -->
    <forms-input
      :errors="errors['password_confirmation']"
      @iconClick="onChangePasswordConfirmationVisible"
      :icon-post="passwordConfirmationFieldIcon"
      :icon-post-interactive="true"
      name="password-confirmation"
      :placeholder="$t('resto.user_password_confirmation_placeholder')"
      ref="password-confirmation"
      required
      :type="passwordConfirmationFieldType"
      v-if="value.isTokenShown"
      v-model="value.passwordConfirm"
    >{{ $t('resto.user_password_confirmation') }}</forms-input>

    <!-- Forgot password -->
    <actions-button
      appearance="subtle"
      @click="onChangeTokenShown"
      class="user-forms-lost-password__link"
    >{{ tokenLabel }}</actions-button>
  </form>
</template>

<script>
import MixinError from '@/helpers/errors'

export default {
  name: 'UserLostPassword',
  
  inject: ['$user'],

  mixins: [MixinError],

  model: { 
    prop: 'value',
    event: 'change',
  },

  props: {
    errors: {
      type: Object | Array,
      default: {}
    },

    hasBack: { 
      type: Boolean,
      default: true,
    },

    value: {
      type: Object,
      required: true,
    }
  },

  data() {
    return {
      success: false,
      key: 1,
      passwordFieldType: 'password',
      passwordConfirmationFieldType: 'password',
    }
  },

  computed: {
    classes() {
      return {
        'user-forms-lost-password': true,
        'flow': true,
      }
    },

    hasErrors() {
      return this.key && Object.keys(this.errors).length > 0
    },

    passwordConfirmationFieldIcon(){
      return this.passwordConfirmationFieldType === 'password' ? 'visible' : 'hidden'
    },

    passwordFieldIcon() {
      return this.passwordFieldType === 'password' ? 'visible' : 'hidden'
    },

    tokenLabel() {
      return this.key && !this.value.isTokenShown ? 
        this.$t('resto.user_lost_password_has_token') : 
        this.$t('resto.user_lost_password_not_has_token')
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    },
  },

  methods: {
    onBack() {
      this.$emit('back')
    },

    onChangePasswordVisible() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },

    onChangePasswordConfirmationVisible() {
      this.passwordConfirmationFieldType = this.passwordConfirmationFieldType === 'password' ? 'text' : 'password'
    },

    onChangeTokenShown() {
      this.value.isTokenShown = !this.value.isTokenShown;
      this.key++
    },

    onSubmit(e) {
      e.preventDefault();
      return
    },
  },
}
</script>
